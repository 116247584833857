<script>
  import { Doughnut } from 'vue-chartjs';
  import { getJamFactorTypes, getJamFactorType, getJamFactorColor } from '@utils/section';

  export default {
    extends: Doughnut,
    props: {
      sections: {
        type: Array,
        required: true,
      },
    },

    data () {
      return {
        types: getJamFactorTypes(),
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                const type = this.types[tooltipItems.index];
                return ' ' + this.$t('constants.section.jamFactor.' + type) + ' : ' + data.datasets[0].data[tooltipItems.index] + '%';
              },
            },
          },
        },
      };
    },

    computed: {
      averageType () {
        let total = 0;
        let average = 0;
        for (const section of this.sections) {
          if (section.jamFactor !== null) {
            total += section.length;
            average += section.jamFactor * section.length;
          }
        }

        if (total > 0) {
          average /= total;
        }

        return getJamFactorType(average);
      },

      data () {
        const data = {};
        for (const type of this.types) {
          data[type] = 0;
        }

        let total = 0;
        for (const section of this.sections) {
          const type = getJamFactorType(section.jamFactor);
          data[type] += section.length;
          total += section.length;
        }

        for (const type of this.types) {
          data[type] = (data[type] / total * 100).toFixed(0);
        }

        return data;
      },

      chartData () {
        return {
          labels: Object.keys(this.data).map(type => this.$t('constants.section.jamFactor.' + type)),
          datasets: [
            {
              data: Object.values(this.data),
              backgroundColor: Object.keys(this.data).map(type => getJamFactorColor(type)),
              hoverBackgroundColor: Object.keys(this.data).map(type => getJamFactorColor(type)),
              borderWidth: 1,
            },
          ],
        };
      },
    },

    watch: {
      data () {
        this.update();
      },
    },

    mounted () {
      this.addPlugin({
        id: 'overview',
        beforeDraw: (chart) => {
          const ctx = chart.chart.ctx;

          const center = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          };

          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = 'bold 1rem Roboto';
          ctx.fillStyle = getJamFactorColor(this.averageType);

          ctx.fillText(
            this.$t('constants.section.jamFactor.' + this.averageType),
            center.x,
            center.y
          );
        },
      });

      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      update () {
        this.$data._chart.data = this.chartData;
        this.$data._chart.options = this.options;

        this.$data._chart.update({ duration: 0 });
      },
    },
  };
</script>
